/*!
 * Theme Name: winchesters-lettings-theme
 * Description: winchesters-lettings-theme Theme for Winchesters Lettings website
 * Author: Lyles Sutherland Ltd.
 * Template: Lyles-Sutherland-Theme
 * Version: 0.0.1
 * Year: June 2017
*/
@import "vendor/bootstrap/bootstrap";
@import "vendor/fontawesome/font-awesome";
@import "vendor/slick/slick";
@import "vendor/slick/slick-theme";
@import "vendor/jquery-cookiebar/jquery-cookiber";
@import "vendor/gravity_forms";

@import "utils/variables";
@import "utils/responsive";
@import "utils/mixins";
@import "utils/typography";
@import "utils/basic";

@import "components/assets";
@import "components/buttons";
@import "components/forms";
@import "components/pagination";
@import "components/testimonials";
@import "components/accordions";

@import "layout/sidebars";
@import "layout/navigations";
@import "layout/footer";
@import "layout/header";
@import "layout/masthead";
@import "layout/content";

@import "teases/post-tease";
@import "teases/staff-tease";
@import "teases/testimonial-tease";

@import "widgets/social-sharing";

@import "wp-properties/properties-pagination";
@import "wp-properties/properties-searchers";
@import "wp-properties/properties";
@import "wp-properties/properties-overview";
@import "wp-properties/single-property";
@import "wp-properties/property-gallery";
@import "wp-properties/properties-assets";
@import "wp-properties/properties-accordions";
@import "wp-properties/properties-search";

@import "pages/front-page";
@import "pages/contact-us";
@import "pages/landlords";
@import "pages/blog";
@import "pages/single-post";
@import "pages/404";
@import "pages/category";
